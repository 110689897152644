import { useStaticQuery, graphql } from "gatsby";

export const useFaqSection = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "FAQs" } }) {
        faqSection {
          faqs {
            questions {
              questionSection {
                questions {
                  question {
                    copy
                    title
                    showQuestion
                  }
                }
                sectionTitle
                showSection
              }
            }
            headingSection {
              heading
            }
          }
        }
      }
    }
  `);
  return data?.wpPage?.faqSection?.faqs;
};
