import React from "react";
import Layout from "../components/layout/layout";
import Header from "../components/Header/Header";
import ISRSeo from "../components/ISRSeo/ISRSeo";
import FaqSection from "../components/Faq/FaqSection/FaqSection";
import Footer from "../components/Footer/Footer";
import { useFaqSeo } from "../hooks/faq/useFaqSeo";

const Faq = () => {
  return (
    <Layout>
      <Header />
      <ISRSeo data={useFaqSeo} title="FAQs" />
      <FaqSection />
      <Footer />
    </Layout>
  );
};

export default Faq;
