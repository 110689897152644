import { useStaticQuery, graphql } from "gatsby";
export const useFaqSeo = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "FAQs" } }) {
        seo {
          canonical
          cornerstone
          focuskw
          fullHead
          metaDesc
          metaKeywords
          metaRobotsNofollow
          metaRobotsNoindex
          opengraphAuthor
          opengraphDescription
          opengraphModifiedTime
          opengraphPublishedTime
          opengraphPublisher
          opengraphSiteName
          opengraphTitle
          opengraphType
          opengraphUrl
          readingTime
          title
          twitterTitle
          schema {
            raw
            pageType
            articleType
          }
          breadcrumbs {
            url
            text
          }
          twitterDescription
          twitterImage {
            mediaItemUrl
            sourceUrl
          }
          opengraphImage {
            mediaItemUrl
            sourceUrl
          }
        }
      }
    }
  `);
  return data?.wpPage;
};
