import React, { useState } from "react";
import { useFaqSection } from "../../../hooks/faq/useFaqSection";
import FaqCollapsible from "../components/FaqCollapsible";
import Spacer from "../../Spacer/Spacer";
import "./FaqSection.scss";

const FaqSection = () => {
  const [update, setUpdate] = useState(false);
  const {
    questions,
    headingSection: { heading },
  } = useFaqSection();

  const queryString = typeof window !== "undefined" ? window.location.hash : "";
  const getId = (string) => {
    return string.replace(/([^A-Za-z0-9[\]{}_.:-])\s?/g, "").toLowerCase();
  };

  const getQuestionId = (string) => {
    return string
      .replace(/([^A-Za-z0-9[\]{} _.:-])?/g, "")
      .replace(/\s+/g, "-")
      .toLowerCase();
  };

  return (
    <div className="container">
      <Spacer size={100} mobileSize={50} />
      <h1 className="text-center">{heading}</h1>
      <hr size="3" className="divider" />
      <section className="faq-section-wrapper">
        {questions &&
          questions.map((section) => {
            const theId = getId(section.questionSection.sectionTitle);
            if (section.questionSection.showSection)
              return (
                <div key={`ol-${theId}`}>
                  <div id={theId} className="faq-section">
                    <h3>{section.questionSection.sectionTitle}</h3>
                    <div className="faq-list">
                      <table className="table">
                        <tbody>
                          {section.questionSection.questions &&
                            section.questionSection.questions.map((item) => {
                              if (item.question.showQuestion)
                                return (
                                  <FaqCollapsible
                                    id={getQuestionId(item.question.title)}
                                    queryString={queryString.replace("#", "")}
                                    key={`qq-${item.question.title}`}
                                    title={item.question.title}
                                    copy={item.question.copy}
                                    setUpdate={setUpdate}
                                    update={update}
                                  />
                                );
                              return null;
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              );
            return null;
          })}
      </section>
      <Spacer size={150} mobileSize={50} />
    </div>
  );
};

export default FaqSection;
