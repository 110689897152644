import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const FaqCollapsible = ({ open, title, copy, setUpdate, id, queryString }) => {
  const [isOpen, setIsOpen] = useState(open);
  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
    setUpdate((prev) => !prev);
    if (typeof window !== "undefined") {
      if (isOpen) {
        window.history.pushState(null, null, " ");
      } else {
        window.history.pushState(null, null, `#${id}`);
      }
    }
  };

  useEffect(() => {
    if (queryString === id) {
      handleFilterOpening();
    }
  }, []);

  return (
    <>
      <tr>
        <td className="fake-td">
          <div id={id} className="fake-anchor" />
        </td>
      </tr>
      <tr
        className={`slide-the-bottom ${isOpen ? "active" : ""}`}
        onClick={handleFilterOpening}
        onKeyDown={handleFilterOpening}
        role="button"
      >
        <td>
          <div className="heading-wrapper">
            <span className="heading">{title}</span>
            <svg width="9" height="7" xmlns="http://www.w3.org/2000/svg">
              <path
                d="m5.253 5.926 3.296-3.767A1 1 0 0 0 7.796.5H1.204A1 1 0 0 0 .45 2.159l3.296 3.767a1 1 0 0 0 1.506 0z"
                fill="#000"
                fillRule="evenodd"
                opacity=".3"
              />
            </svg>
          </div>
        </td>
      </tr>
      <tr className={`slide-from-top ${isOpen ? "active" : ""}`}>
        <td
          className="content-wrapper content "
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: copy,
          }}
        />
      </tr>
    </>
  );
};

export default FaqCollapsible;

FaqCollapsible.defaultProps = {
  open: null,
  id: null,
};

FaqCollapsible.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  setUpdate: PropTypes.func.isRequired,
  id: PropTypes.string,
  queryString: PropTypes.string.isRequired,
};
